"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _splice = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/splice"));
var _default = {
  props: ['src', 'index'],
  data: function data() {
    return {
      positions: {
        clientX: undefined,
        clientY: undefined,
        movementX: 0,
        movementY: 0
      },
      isOpen: true
    };
  },
  methods: {
    dragMouseDown: function dragMouseDown(event) {
      event.preventDefault();
      // get the mouse cursor position at startup:
      this.positions.clientX = event.clientX;
      this.positions.clientY = event.clientY;
      document.onmousemove = this.elementDrag;
      document.onmouseup = this.closeDragElement;
    },
    touchDown: function touchDown(event) {
      event.preventDefault();
      // get the mouse cursor position at startup:
      this.positions.clientX = event.clientX;
      this.positions.clientY = event.clientY;
      document.ontouchmove = this.touchStart;
      document.ontouchend = this.closeDragElement;
    },
    elementDrag: function elementDrag(event) {
      event.preventDefault();
      this.positions.movementX = this.positions.clientX - event.clientX;
      this.positions.movementY = this.positions.clientY - event.clientY;
      this.positions.clientX = event.clientX;
      this.positions.clientY = event.clientY;
      // set the element's new position:
      this.$refs.draggableContainer.style.top = this.$refs.draggableContainer.offsetTop - this.positions.movementY + 'px';
      this.$refs.draggableContainer.style.left = this.$refs.draggableContainer.offsetLeft - this.positions.movementX + 'px';
    },
    touchStart: function touchStart(event) {
      event.preventDefault();
      // grab the location of touch
      this.positions.movementX = this.positions.clientX - event.targetTouches[0].pageX;
      this.positions.movementY = this.positions.clientY - event.targetTouches[0].pageY;
      this.positions.clientX = event.targetTouches[0].pageX;
      this.positions.clientY = event.targetTouches[0].pageY;

      // assign box new coordinates based on the touch.
      this.$refs.draggableContainer.style.top = this.$refs.draggableContainer.offsetTop - this.positions.movementY + 'px';
      this.$refs.draggableContainer.style.left = this.$refs.draggableContainer.offsetLeft - this.positions.movementX + 'px';
    },
    closeDragElement: function closeDragElement() {
      document.onmouseup = null;
      document.onmousemove = null;
      document.ontouchmove = null;
      document.ontouchend = null;
    },
    closeCam: function closeCam(value) {
      var _context;
      (0, _splice.default)(_context = kiwi.cams.viewedUsers).call(_context, value, 1);
      this.$state.$emit('cam.close', this.src, this.$state.getActiveNetwork());
    },
    toggleIsOpen: function toggleIsOpen() {
      this.isOpen = !this.isOpen;
    }
  }
};
exports.default = _default;