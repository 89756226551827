"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _PopUp = _interopRequireDefault(require("@/components/PopUp"));
var _default = {
  components: {
    PopUp: _PopUp.default
  },
  data: function data() {
    return {
      items: kiwi.cams.viewedUsers
    };
  },
  methods: {}
};
exports.default = _default;