"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('div', {
    ref: "draggableContainer",
    style: {
      top: 50 + _vm.index * 3.25 + '%'
    },
    attrs: {
      "id": "draggable-container"
    }
  }, [_c('i', {
    staticClass: "fa fa-window-close",
    staticStyle: {
      "margin": "0px 3px",
      "font-size": "22px",
      "float": "right",
      "transform": "translateY(22px)"
    },
    attrs: {
      "aria-hidden": "true"
    },
    on: {
      "click": function click($event) {
        return _vm.closeCam(_vm.index);
      }
    }
  }), !this.isOpen ? _c('i', {
    staticClass: "fa fa-window-maximize",
    staticStyle: {
      "margin": "0px 3px",
      "font-size": "22px",
      "float": "right",
      "transform": "translateY(22px)"
    },
    attrs: {
      "aria-hidden": "true"
    },
    on: {
      "click": function click($event) {
        return _vm.toggleIsOpen(_vm.index);
      }
    }
  }) : _vm._e(), this.isOpen ? _c('i', {
    staticClass: "fa fa-window-minimize",
    staticStyle: {
      "margin": "0px 3px",
      "font-size": "22px",
      "float": "right",
      "transform": "translateY(22px)"
    },
    attrs: {
      "aria-hidden": "true"
    },
    on: {
      "click": function click($event) {
        return _vm.toggleIsOpen(_vm.index);
      }
    }
  }) : _vm._e(), _c('div', {
    attrs: {
      "id": "draggable-header"
    },
    on: {
      "mousedown": function mousedown($event) {
        $event.preventDefault();
        return _vm.dragMouseDown.apply(null, arguments);
      },
      "touchstart": function touchstart($event) {
        $event.preventDefault();
        return _vm.touchDown.apply(null, arguments);
      }
    }
  }, [_c('div', {
    staticClass: "header"
  }, [_vm._v(_vm._s(_vm.src) + " ")])]), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: this.isOpen,
      expression: "this.isOpen"
    }],
    staticStyle: {
      "background-color": "aliceblue"
    }
  }, [_c('iframe', {
    staticStyle: {
      "height": "250px"
    },
    attrs: {
      "src": 'https://irc.chateachat.com:8443/?user=' + _vm.src,
      "frameborder": "0",
      "allow": "camera; microphone"
    }
  })])])]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;